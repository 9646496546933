<template>
    <div class="gob_editor-overlay">
        <div class="gob_editor-overlay__content" v-if="showOverlay">
            <slot></slot>
            <div class="gob_editor-overlay__footer">
                <a v-on:click.prevent="closeOverlay" href="#" class="gob_button">
                    <slot name="button"></slot>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data: function() {
            return {
                showOverlay: true
            }
        },

        methods: {
            closeOverlay() {
                this.showOverlay = false;
            }
        }
    }
</script>