<template>
    <div class="gob_input__input">
        <datepicker :date="startTime"
                    :inputName="name" :option="option"
                    :limit="limit" :inputStyle="inputStyle" :language="language">
        </datepicker>
    </div>
</template>

<script>
    import Datepicker from './Datepicker.vue';
    import moment from 'moment';
    import locale_fr from "moment/locale/fr";
    import locale_de from "moment/locale/de";
    import locale_nl from "moment/locale/nl";

    export default {
        props: {
            name: {
                type: String,
                required: true
            },
            language: {
                type: String,
                required: true
            }
        },
        created() {
            this.moment = moment();

            switch (this.language) {
                case 'de':
                    moment.locale('de', locale_de);
                    this.buttons = {
                        ok: 'OK',
                        cancel: 'Abbrechen'
                    };
                    break;
                case 'fr':
                    moment.locale('fr', locale_fr);
                    this.buttons = {
                        ok: 'OK',
                        cancel: 'Cancel'
                    };
                    break;
                case 'nl':
                    moment.locale('nl', locale_nl);
                    this.buttons = {
                        ok: 'OK',
                        cancel: 'Cancel'
                    };
                    break;
            }

            let weekdays = moment.weekdaysShort().splice(1);
            weekdays = weekdays.concat(moment.weekdaysShort().splice(0, 1));

            this.option = {
                type: 'min',
                week: weekdays,
                month: moment.months(),
                format: 'DD.MM.YYYY HH:mm',
                placeholder: '',

                inputStyle: {
                    'display': 'block',
                    'padding': '0.5em 0.7em',
                    'line-height': '22px',
                    'font-size': '15px',
                    'border': '1px solid #707070',
                    'box-shadow': 'none',
                    'border-radius': '0',
                    'color': '#707070'
                },
                color: {
                    header: '#0081C1',
                    headerText: '#FFF',
                    checkedDay: 'rgb(0,144,122)'
                },
                buttons: this.buttons,
                overlayOpacity: 0.5, // 0.5 as default
                dismissible: true // as true as default
            }
        },
        data() {
            return {
                startTime: {
                    time: ''
                },
                moment: null,
                limit: [
                    {
                        type: 'min',
                        available: [2, 3, 4, 5, 6, 7]
                    },
                    {
                        type: 'fromto',
                        from: '2018-03-16', // Date.now(),
                        to: '2019-10-27'
                    }
                ],
                buttons: {
                    ok: 'OK',
                    cancel: 'Cancel'
                },
                option: {}
            }
        },
        components: {
            Datepicker
        }
    }
</script>