export default class InputCheckbox {
	static init(el) {
		return new InputCheckbox(el);
	}

	constructor(el) {
		this.domElements = {
			root: el,
			label: el.querySelector('.gob_input__label'),
			input: el.querySelector('.gob_input__input')
		};

		this.attachListeners();
	}

	attachListeners() {
		if (!!this.domElements.label) {
			this.domElements.label.addEventListener('click', () => {
				this.domElements.input.click();
			});
		}
	}
}
