export default class BookingForm {
	static init(el) {
		return new BookingForm(el);
	}

	constructor(el) {
		if (!el.classList.contains('successfully-submitted')) {
			this.domElements = {
				root: el,
				inputsBookingType: Array.from(el.querySelectorAll('.gob_booking-form-booking-type .gob_input-radio')),
				inputBookingTypeNone: document.getElementById('bookingType-none'),
				conditionsCheckboxInput: document.getElementById('conditions').querySelector('.gob_input__input'),
				buttonReset: el.querySelector('button[type="reset"]')
			};
			this.isConditionsChecked = false;

			const inputBookingTypeChecked = el.querySelector('input[type="radio"]:checked');

			if (inputBookingTypeChecked !== null) {
				this.domElements.inputBookingTypeChecked = inputBookingTypeChecked.parentNode;
			}

			this.attachListeners();
			this.onBookingTypeChange(this.domElements.inputBookingTypeChecked);
		}
	}

	attachListeners() {
		this.domElements.conditionsCheckboxInput.addEventListener('change', () => {
			this.isConditionsChecked = this.domElements.conditionsCheckboxInput.checked;
		});
		this.domElements.inputsBookingType
			.forEach(inputBookingType => inputBookingType.addEventListener('change', () => this.onBookingTypeChange(inputBookingType)));

		if (!!this.domElements.buttonReset) {
			this.domElements.buttonReset.addEventListener('click', evt => this.onButtonResetClick(evt));
		}
	}

	onBookingTypeChange(inputBookingType) {
		const isGuided = inputBookingType.dataset.isGuided === 'true'
		this.domElements.root.classList.toggle('no-guided-tour', !isGuided);
		// If tour is not guided, check conditions. Otherwise, set conditions checkbox to its previous state.
		this.domElements.conditionsCheckboxInput.checked = !isGuided || this.isConditionsChecked && isGuided;
	}

	onButtonResetClick(evt) {
		this.onBookingTypeChange(this.domElements.inputBookingTypeNone);
	}
};
