<template>
    <div class="slider">
        <div class="slider__slides" ref="slideshow">
            <slot></slot>
        </div>

        <div class="slider__prev-next" ref="slideshow-prev-next">
            <button class="slider__prev-next__button slider__button--previous" ref="slideshowPrevious">
                <svg class="slider__prev-next__icon" aria-label="zurück" role="presentation" focusable="false">
                    <use xlink:href="#icon-arrow-left"></use>
                </svg>
            </button>
            <button class="slider__prev-next__button slider__button--next" ref="slideshowNext">
                <svg class="slider__prev-next__icon" aria-label="weiter" role="presentation" focusable="false">
                    <use xlink:href="#icon-arrow-right"></use>
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            pageDots: {
                type: Boolean,
                default: false
            },
            loop: {
                type: Boolean,
                default: true
            },
            autoPlay: {
                default: false
            },
            lazyLoad: {
                default: false
            }
        },

        data() {
            return {
                slider: null,
            }
        },

        mounted() {
            this.initSlideshow();

            this.slider.on('settle', (event, cellElement) => {
                this.slider.resize()

            });
        },

        methods: {
            initSlideshow() {
                const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)').matches;

                this.slider = new Flickity(this.$refs.slideshow, {
                    prevNextButtons: false,
                    pageDots: this.pageDots,
                    cellAlign: 'center',
                    contain: true,
                    wrapAround: this.loop,
                    dragThreshold: 20,
                    autoPlay: prefersReducedMotion ? false : this.autoPlay,
                    pauseAutoPlayOnHover: true,
                    lazyLoad: this.lazyLoad,
                    on: {
                        ready: function () {
                            setTabIndex();
                        },
                        change: function( index ) {
                            setTabIndex();
                        }
                    }
                });

                function setTabIndex() {
                    let slides = document.getElementsByClassName('gob_hero-slider__slide');
                    let selectedSlide = document.getElementsByClassName('gob_hero-slider__slide is-selected');
                    for (let i = 0; i < slides.length; i++) {
                        slides[i].setAttribute('tabindex', '-1');
                        selectedSlide[0].setAttribute('tabindex', '0');
                    }
                }

                // custom previous & next buttons
                let previousButton = this.$refs.slideshowPrevious;
                previousButton.addEventListener('click', () => {
                    this.slider.previous();
                });

                let nextButton = this.$refs.slideshowNext;
                nextButton.addEventListener('click', () => {
                    this.slider.next();
                });
            }
        }
    }
</script>