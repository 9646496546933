export default class Observable {
	constructor() {
		this.observers = new Map();
	}

	fireEvent(name, payload) {
		if (this.observers.has(name)) {
			this.observers.get(name).forEach(observer => observer(payload));
		}
	}

	addEventListener(name, listener) {
		if (!this.observers.has(name)) {
			this.observers.set(name, []);
		}

		this.observers.get(name).push(listener);
	}

	removeEventListener(name, listener) {
		if (this.observers.has(name)) {
			const listeners = this.observers.get(name);
			const listenerIndex = listeners.indexOf(listener);

			if (listenerIndex !== -1) {
				this.observers.set(name, listeners.splice(listenerIndex, 1));
			}
		}
	}
}
