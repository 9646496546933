<template>
    <div class="collapsible" :class="{'is-open': isOpen}">
        <button class="collapsible__header" v-on:click.prevent="isOpen = !isOpen" :title="buttonLabel" :aria-expanded="isOpen.toString()">
            <slot name="header"></slot>
        </button>
        <transition-auto-expand :aria-hidden="!isOpen">
            <div class="collapsible__body">
                <slot></slot>
            </div>
        </transition-auto-expand>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isOpen: false
            }
        },
        computed: {
            buttonLabel() {
                return this.isOpen ? 'zuklappen' : 'aufklappen'
            }
        }
    }
</script>
