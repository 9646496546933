import Observable from './Observable.js';

export default class Modal extends Observable {
	static init(el) {
		return new Modal(el);
	}

	constructor(el) {
		super();

		this.domElements = {
			root: el,
			buttonClose: el.querySelector('.modal__close-button')
		};

		this.attachListeners();
	}

	attachListeners() {
		if (!!this.domElements.buttonClose) {
			this.domElements.buttonClose.addEventListener('click', () => this.onButtonCloseClick());
		}
	}

	onButtonCloseClick() {
		this.close();
	}

	open() {
		this.domElements.root.classList.add('is-open');
		this.fireEvent('open');
	}

	close() {
		this.domElements.root.classList.remove('is-open');
		this.fireEvent('close');
	}

	getRootElement() {
		return this.domElements.root;
	}
}
