import CookieManager from './CookieManager';
import Observable from './Observable';

export default class ExternalMedium extends Observable {
	static init(el) {
		return new ExternalMedium(el);
	}

	constructor(el) {
		super();

		this.domElements = {
			root: el,
			consentButton: el.querySelector('.gob_tag-external-medium-consent-button'),
			content: el.querySelector('.gob_tag-external-medium-content')
		};

		this.cookieConsentGiven = this.domElements.content.classList.contains('-consent-given');

		this.setCookieConsentGiven(CookieManager.getInstance().isCookieSet('cookie_consent_external-media'));
		this.attachListeners();
	}

	attachListeners() {
		if (!!this.domElements.consentButton) {
			this.domElements.consentButton.addEventListener('click', () => this.onButtonConsentClick());
		}
	}

	onButtonConsentClick() {
		this.fireEvent('consentGiven');
		this.setCookieConsentGiven(true);
	}

	setCookieConsentGiven(consentGiven) {
		if (this.cookieConsentGiven !== consentGiven) {
			if (consentGiven) {
				this.domElements.root.classList.add('-consent-given');
				this.domElements.content.src = this.domElements.content.dataset.src;
			} else {
				this.domElements.root.classList.remove('-consent-given');
				this.domElements.content.dataset.src = this.domElements.content.src;
				this.domElements.content.removeAttribute('src');
			}

			this.cookieConsentGiven = consentGiven;
		}
	}
}
