<template>

</template>
<script>

    export default {
        data() {
            return {
                isOpen: false,
                activeSubnavigation: null
            }
        },

        methods: {
            openSubnavigation(title) {
                this.activeSubnavigation = title;
            },

            isActiveSubnavigation(title) {
                return this.activeSubnavigation == title;
            },

            subnavigationClass(title) {
                return this.isActiveSubnavigation(title) ? 'is-open' : '';
            }
        }
    }
</script>