export default class Gallery {
	static init(el) {
		return new Gallery(el);
	}

	constructor(el) {
		this.el = el;
		this.itemEls = Array.from(this.el.querySelectorAll('.gob_gallery__item'));

		if (this.itemEls.length > 0) {
			this.captionLinkEls = Array.from(this.el.querySelectorAll('.gob_image-container__caption a'));
			this.photoSwipeEl = this.el.querySelector('.gob_gallery__zoom-modal');

			this.id = this.el.id;
			this.photoSwipeInitialized = false;

			this.attachListeners();
			this.initComponents();
		}
	}

	attachListeners() {
		window.addEventListener('load', () => this.onWindowLoad(), {
			once: true,
			passive: true
		});
		this.itemEls.forEach(itemEl => {
			itemEl.addEventListener('click', () => this.onItemClick(itemEl), { passive: true });
		});
		// Allow links inside captions to work.
		this.captionLinkEls.forEach(captionLinkEl => {
			captionLinkEl.addEventListener('click', evt => evt.stopPropagation());
		});
	}

	initComponents() {
		this.items = this.itemEls.map(itemEl => {
			const item = {
				src: itemEl.dataset.src,
				w: parseInt(itemEl.dataset.width),
				h: parseInt(itemEl.dataset.height),
				msrc: itemEl.dataset.srcThumb,
				el: itemEl
			};

			const captionEl = itemEl.querySelector('.gob_image-container__caption');

			// Safe double negation: `Element.querySelector` can only return the elements or `null`.
			if (!!captionEl) {
				item.title = captionEl.innerHTML;
			}

			return item;
		});
	}

	onWindowLoad() {
		const params = new URLSearchParams(window.location.href);
		const gid = params.get('gid');
		const pid = params.get('pid');

		// Safe double negation: `URLSearchParams.get` can only return the value or `null`.
		if (!!gid && !!pid && gid === this.id) {
			// URL parameter index starts at `1`.
			const index = parseInt(pid) - 1;

			if (index < this.items.length) {
				this.openPhotoSwipe({
					galleryUID: this.id,
					index: index
				});
			}
		}
	}

	onItemClick(itemEl) {
		this.openPhotoSwipe({
			galleryUID: this.id,
			index: parseInt(itemEl.dataset.index)
		});
	}

	openPhotoSwipe(options) {
		// Reusing PhotoSwipe doesn't work. No clue why.
		// this.photoSwipe.options.index = parseInt(itemEl.dataset.index);

		// if (this.photoSwipeInitialized) {
		// 	this.photoSwipe.open();
		// } else {
		// 	this.photoSwipe.init();
		// }
		this.photoSwipe = new PhotoSwipe(this.photoSwipeEl, PhotoSwipeUI_Default, this.items, options);
		this.photoSwipe.init();
	}
}
