import Observable from './Observable.js';

export default class CookieSettings extends Observable {
	static init(el) {
		return new CookieSettings(el);
	}

	constructor(el) {
		super();

		this.domElements = {
			root: el,
			ckeckBoxes: el.querySelectorAll('.gob_input-checkbox'),
			buttonCloseOptions: el.querySelector('.cookie-settings__close-options-button'),
			buttonCustomize: el.querySelector('.cookie-settings__control--customize'),
			buttonSave: el.querySelector('.cookie-settings__control--save'),
			buttonDecline: el.querySelector('.cookie-settings__control--decline'),
			buttonAccept: el.querySelector('.cookie-settings__control--accept')
		};
		this.options = new Map();

		this.initComponents();
		this.attachListeners();
	}

	initComponents() {
		this.domElements.ckeckBoxes.forEach(toggleEntry => {
			this.options.set(toggleEntry.id, !toggleEntry.classList.contains('is-optional'));
		});
	}

	attachListeners() {
		this.domElements.ckeckBoxes.forEach(toggleEntry => {
			const toggleEntryInput = toggleEntry.querySelector('input');

			if (!!toggleEntryInput) {
				toggleEntryInput.addEventListener('change', () => this.onToggleEntryChange(toggleEntry, toggleEntryInput));
			}
		});
		this.domElements.buttonCloseOptions.addEventListener('click', () => this.onButtonOptionsCloseClick());
		this.domElements.buttonCustomize.addEventListener('click', () => this.onButtonCustomizeClick());
		this.domElements.buttonSave.addEventListener('click', () => this.onButtonSaveClick());
		this.domElements.buttonDecline.addEventListener('click', () => this.onButtonDeclineClick());
		this.domElements.buttonAccept.addEventListener('click', () => this.onButtonAcceptClick());
	}

	onToggleEntryChange(toggleEntry, toggleEntryInput) {
		this.options.set(toggleEntry.id, toggleEntryInput.checked);
	}

	onButtonCustomizeClick() {
		this.domElements.root.classList.add('-options-visible');
	}

	onButtonOptionsCloseClick() {
		this.domElements.root.classList.remove('-options-visible');
	}

	onButtonSaveClick() {
		this.fireEvent('close');
	}

	onButtonDeclineClick() {
		this.options.forEach((value, key) => this.options.set(key, false));
		this.update(this.options);
		this.fireEvent('close');
	}

	onButtonAcceptClick() {
		this.options.forEach((value, key) => this.options.set(key, true));
		this.update(this.options);
		this.fireEvent('close');
	}

	fireEvent(name) {
		super.fireEvent(name, this.options);
	}

	update(options) {
		this.domElements.ckeckBoxes.forEach(toggleEntry => {
			const id = toggleEntry.id;
			const toggleEntryInput = toggleEntry.querySelector('input');

			if (!!toggleEntryInput) {
				toggleEntryInput.checked = options.get(id);
			}

			this.options.set(id, options.get(id));
		});
	}
}
