<template>
    <transition name="auto-expand" v-on:enter="enter"
                v-on:before-leave="beforeLeave"
                v-on:after-enter="afterEnter">
        <slot></slot>
    </transition>
</template>

<script>
    export default {
        methods: {
            enter: function(el) {
                el.style.height = 'auto'
                var endWidth = getComputedStyle(el).height
                el.style.height = '0px'
                el.offsetHeight // force repaint
                el.style.height = endWidth;
            },
            afterEnter: function(el) {
                el.style.height = 'auto'
            },
            beforeLeave: function(el) {
                el.style.height = getComputedStyle(el).height
                el.offsetHeight // force repaint
                el.style.height = '0px'
            }
        }
    }
</script>