import BookingformDatepicker from './components/BookingformDatepicker.vue';
import Collapsible from './components/Collapsible.vue';
import Datepicker from './components/Datepicker.vue';
import EditorOverlay from './components/EditorOverlay.vue';
import Gallery from './components/Gallery.js';
import MainNavigation from './components/MainNavigation.vue';
import Slider from './components/Slider.vue';
import TransitionAutoExpand from './components/transitions/TransitionAutoExpand.vue';
import Modal from './components/Modal';
import CookieManager from './components/CookieManager';
import CookieSettings from './components/CookieSettings';
import ExternalMedium from './components/ExternalMedium';
import BookingForm from './components/BookingForm';
import InputCheckbox from './components/InputCheckbox';

export default class MainController {
	static init(window, document) {
		return new this(window, document);
	}

	constructor() {
		this.pageElement = document.getElementById('page');
		this.cookieSettingsElement = document.getElementById('legal-cookie-settings');

		window.addEventListener('load', () => this.onWindowLoad(), {
			once: true,
			passive: true
		});
		this.initComponents();
	}

	onWindowLoad() {
		this.attachListeners();
	}

	attachListeners() {
		window.addEventListener('resize', () => this.onWindowResize(), {
			passive: true
		});

		if (!!this.cookieSettingsElement) {
			document.getElementById('legal-cookie-settings').addEventListener('click', evt => this.onCookieSettingsElementClick(evt));
		}

		if (!!this.cookieSettings) {
			this.cookieSettings.addEventListener('close', options => this.onCookieSettingsClose(options));
		}
	}

	initComponents() {
		new Vue({
			components: {
				BookingformDatepicker,
				Collapsible,
				Datepicker,
				EditorOverlay,
				MainNavigation,
				Slider,
				TransitionAutoExpand
			}
		}).$mount(this.pageElement);

		// Legacy elements
		this.initGlobalElements();

		// Cookie settings
		this.cookieSettingsModal = Modal.init(document.getElementById('cookie-settings-modal'));
		this.cookieSettings = CookieSettings.init(document.getElementById('cookie-settings'));

		this.loadCookieSettings();

		if (!CookieManager.getInstance().isCookieSet('cookie_consent_shown')) {
			this.cookieSettingsModal.open();
		} else {
			this.updateElementsWhichNeedCookieConsent();
		}

		// Galleries
		Array.from(document.querySelectorAll('.gob_gallery')).forEach(Gallery.init);

		// External media
		this.externalMedia = Array.from(document.querySelectorAll('.gob_tag-external-medium')).map(el => {
			const externalMedium = ExternalMedium.init(el);

			externalMedium.addEventListener('consentGiven', () => {
				if (!CookieManager.getInstance().isCookieSet('cookie_consent_external-media')) {
					CookieManager.getInstance().setCookie('cookie_consent_external-media', true);
					this.loadCookieSettings();
					this.updateElementsWhichNeedCookieConsent([externalMedium]);
				}
			});

			return externalMedium;
		});

		// Booking forms
		Array.from(document.querySelectorAll('.gob_booking-form')).forEach(BookingForm.init);

		// Inputs
		Array.from(document.querySelectorAll('.gob_input-checkbox')).forEach(InputCheckbox.init);
	}

	onWindowResize() {
		document.documentElement.style.setProperty('--modal-height', `${window.innerHeight}px`);
	}

	onCookieSettingsElementClick(evt) {
		evt.preventDefault();
		this.cookieSettingsModal.open();
	}

	onCookieSettingsClose(options) {
		CookieManager.getInstance().setCookie('cookie_consent_shown', true);
		this.updateCookies(options);
		this.updateElementsWhichNeedCookieConsent();

		this.cookieSettingsModal.close();
	}

	loadCookieSettings() {
		const cookieCategories = ['statistics', 'external-media'];
		const consentPrefix = 'cookie_consent_';
		const cookies = new Map();

		cookieCategories.forEach(cookieCategory => {
			const cookieName = consentPrefix + cookieCategory;
			cookies.set(cookieCategory, CookieManager.getInstance().isCookieSet(cookieName));
		});

		this.cookieSettings.update(cookies);
	}

	updateCookies(options) {
		options.forEach((value, key) => CookieManager.getInstance().setCookie(`cookie_consent_${key}`, value));
	}

	updateElementsWhichNeedCookieConsent(elementsToExclude) {
		elementsToExclude = !!elementsToExclude ? elementsToExclude : [];

		if (!!this.externalMedia) {
			this.externalMedia
				.filter(externalMedium => !elementsToExclude.includes(externalMedium))
				.forEach(externalMedium => {
					externalMedium.setCookieConsentGiven(CookieManager.getInstance().isCookieSet('cookie_consent_external-media'));
				});
		}
	}

	// Legacy functions
	loadScript(src, cbk) {
		let scriptElement = document.createElement('script');

		if (cbk !== undefined) {
			scriptElement.addEventListener('load', cbk);
		}

		document.getElementById('site-footer').appendChild(scriptElement);
		scriptElement.src = src;
	}

	geocodeAddress(geocoder, address, map) {
		geocoder.geocode({
			address: address
		}, function (results, status) {
			if (status === 'OK') {
				let result = results[0].geometry.location;

				map.setCenter(result);
				new google.maps.Marker({
					map: map,
					position: result
				});
			}
		});
	}

	initRoutePlanners(routePlannerElements) {
		let googleMapsScriptSrc = pageElement.getAttribute('data-google-maps-src');
		pageElement.removeAttribute('data-google-maps-src');

		loadScript(googleMapsScriptSrc, () => {
			for (let routePlanner of routePlannerElements) {
				let mapElement = routePlanner.querySelector('.gob_route-planner__map');
				let id = mapElement.id;
				let startingAddressInput = document.getElementById(id + '-form_address');
				let destinationAddress = mapElement.getAttribute('data-destination');
				mapElement.removeAttribute('data-destination');

				let geocoder = new google.maps.Geocoder();
				let map = new google.maps.Map(mapElement, {zoom: 16});

				geocodeAddress(geocoder, destinationAddress, map);

				routePlanner.querySelector('.gob_button').addEventListener('click', (evt) => {
					evt.preventDefault();
					let destinationUrl = "http://maps.google.de/maps?saddr=" + startingAddressInput.value + "&daddr=" + encodeURI(destinationAddress);

					window.open(destinationUrl, '_blank');
				});
			}
		});
	}

	initEditorMarks(editorMarkElements) {
		for (let editorMarkElement of editorMarkElements) {
			let tooltipElement = editorMarkElement.querySelector('.gob_editor-mark__tooltip');

			editorMarkElement.addEventListener('mouseenter', () => {
				tooltipElement.classList.add('is-visible');
			});
			editorMarkElement.addEventListener('mouseleave', () => {
				tooltipElement.classList.remove('is-visible');
			});
		}
	}

	initGlobalElements() {
		// Route planners
		let routePlannerElements = document.querySelectorAll('.gob_route-planner');

		if (routePlannerElements.length > 0) {
			initRoutePlanners(routePlannerElements);
		}

		// Editor marks
		let editorMarkElements = document.querySelectorAll('.gob_editor-mark--compact');

		if (editorMarkElements.length > 0) {
			initEditorMarks(editorMarkElements);
		}
	}
}
