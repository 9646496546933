import Observable from './Observable';

export default class CookieManager extends Observable{
	static getInstance() {
		if (CookieManager._instance === undefined) {
			CookieManager._instance = new this();
		}

		return CookieManager._instance;
	}

	constructor() {
		super();

		this.cookies = new Map();

		this.parseCookies();
	}

	parseCookies() {
		document.cookie.split('; ').forEach(cookieString => {
			const cookie = cookieString.split('=');

			if (!!cookie[1]) {
				this.cookies.set(cookie[0], cookie[1] === 'true');
			}
		});
	}

	getCookies() {
		return this.cookies;
	}

	hasCookie(key) {
		return this.cookies.has(key);
	}

	setCookie(key, value) {
		document.cookie = `${key}=${value};path=/`;
		this.parseCookies();
		this.fireEvent('update', { key, value });
	}

	getCookie(key) {
		if (!this.hasCookie(key)) {
			throw new Error(`Invalid cookie ${key}.`);
		}

		return this.cookies.get(key);
	}

	isCookieSet(key) {
		return this.hasCookie(key) && !!this.getCookie(key);
	}
}
